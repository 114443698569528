import React from "react"

import SEO from "/components/seo"
import NorthAmerica from "components/Holiday2020/Nike/NorthAmerica"

const NikenaHoliday2020 = () => (
  <>
    <SEO
      title="Thesis | Holiday 2020: Nike NA Ops + Creative"
      description="Happy holidays from Thesis, a full-service digital agency in Portland, OR where strategy, creative, tech, and partner services combine to humanize the digital relationship between brands and people. Using data as the driver, our versatile work has the range to meet people where they are and the relevance to engage people as they are."
    />
    <NorthAmerica />
  </>
)

export default NikenaHoliday2020
